import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/amityville-2-the-possession-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 haunted house movie, Amityville 2: The Possession"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 01</h1>
                    <h2>Amityville 2: The Possession</h2>
                    <h3>February 20, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/amityville-2-the-possession">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:06.94)
                            this is the brother&#39;s grim podcast presented by the cinema suicide film society on sub stack i&#39;m brian white i&#39;m the editor in grief a cinema suicide joined by my co host and actual brother dave white dave you doing</p>
                            <p>Dave (00:19.645)
                            i&#39;m doing all right</p>
                            <p>Bryan White (00:21.3)
                            yeah so we we practically grew up in neighborhood video stores and the steady diet of ter garbage that those shops provided us with continues unabated to this day and there&#39;s no one else i really enjoy chopping it up with more about trashy movies and dave so before we get into what we watch someone to gie you give little taste give a little taste of what&#39;s coming up here</p>
                            <p>Bryan White (01:30.26)
                            all right so so we didn&#39;t actually watch the amity villa horror we watched we watched this one instead</p>
                            <p>Dave (01:37.385)
                            we watched the italian mdvillhor the italian ville horror</p>
                            <p>Bryan White (01:40.12)
                            he is a well have been</p>
                            <p>Bryan White (03:40.12)
                            amory ville to the possession</p>
                            <p>Dave (03:43.285)
                            this movie is tasteless as hell</p>
                            <p>Bryan White (03:45.58)
                            unbelievably weird it is so so weird but we&#39;re goin to get into it the big explosion at the end that you hear is them actually blowing up the house in what was one of the weirdest effects in the whole movie completely ne unnecessary but i&#39;m glad it was in there</p>
                            <p>Dave (04:02.625)
                            blowing up the house that the family then moves into like six months later</p>
                            <p>Bryan White (04:07.76)
                            right well yeah well the time line is a little weird on this one i suppose we&#39;ll get into it but a few little i feel little facts about this before we before we dive in this is released in nineteen eighty two which is not a bad year for horror movies we got other movies released that year we got polterguized the thing my bloody valentine butcher baker nightmare maker which is one we got to have to talk about it another time friday the thirteenth part three and hollo</p>
                            <p>Dave (04:27.605)
                            my blooded valentine</p>
                            <p>Bryan White (04:37.04)
                            in part three the latter of which was also written by the guy who wrote this one with creep show slumber party massacre and lastly tennebray um there are there are three formal amity ville sequels though that&#39;s debatable i suppose because he&#39;s not really connective tissue between any of these movies um the this one ammerdyville two had a slightly bigger budget than the first one which made ninety million dollars in box off</p>
                            <p>Bryan White (05:07.12)
                            receipts and that&#39;s in nineteen seventy nine that&#39;s a ship load of money that&#39;s like jaws money m amity ville two made slight made significantly less than the previous with just with just over twelve million in receipts the one that follows is amitville three d the lass of the formal sequels was in nineteen eighty nine with the evil escapes which was made for t v and it was directed by the writer of the original amity ville horror sandor stern was kind of crazy awesome name and fun fact</p>
                            <p>Bryan White (05:37.26)
                            his sons were in the band youth brigade</p>
                            <p>Dave (05:40.445)
                            also that movie is about a haunted lamp</p>
                            <p>Bryan White (05:44.24)
                            how many of them are about hounded lamps okay because</p>
                            <p>Dave (05:46.485)
                            just the one just the one that then there&#39;s a haunted clock after</p>
                            <p>Bryan White (05:50.32)
                            jesus christ so it is a many villes quite possibly the most exploited story in all of horror history so at first i thought that without the remake there were thirty nine movies but it turns out there are forty two films in all um and it seems like thirty of them were made in the last ten years which just strikes me as so weird like who is watching these movies</p>
                            <p>Bryan White (06:19.86)
                            like because this stuff is not born in a vacuum like somebody is obviously buying them or renting them in quantity to sort of continue this trend of like nobody remakes or horror movies that just kind of bear the title because i looked at a few of them and like some some amity ville movies are just like zombie movies that just have amity vile in the title</p>
                            <p>Dave (06:43.145)
                            yeah i don&#39;t know about you but the last three years people have had a lot of free time and amazon needs content</p>
                            <p>Bryan White (06:48.4)
                            that this is true this is true god i wonder how many of these are available on toby i would imagine like all of them yea so here&#39;s here&#39;s a taste</p>
                            <p>Dave (06:55.205)
                            oh probably all of them six of them will probably be made by t b</p>
                            <p>Bryan White (07:00.18)
                            oh god some of them i wonder like i didn&#39;t look too deep because like who&#39;s got the time but i would imagine like half of them were made by the asylum you know when they were just like untitled movies and then then later on they were like i don&#39;t know called ten of them amodyville something i think there is an amodyville dynasore one so there&#39;s amodyvlle curse modyville doll house modyville terror amodyville asylum modyville cop</p>
                            <p>Dave (07:17.245)
                            amy ville dinasor</p>
                            <p>Bryan White (07:30.36)
                            amity clown house ville in the hood which might be my favorite of all of them and amiyvlle in space which is about the house in space they do kind of sound like the leprconsequels becuse that&#39;s another thing like who the fuck is watching those leprcon movies like oh no because what i was about to say was like jesus christ because was about to say was like if i knew anybody who&#39;d watched those sequels i don&#39;t want to know i don&#39;t want to speak to them ever again</p>
                            <p>Dave (07:40.885)
                            but he sounds like the leper conceals as well</p>
                            <p>Dave (07:48.425)
                            me i am i have watched at least all of them in the last year</p>
                            <p>Dave (07:58.745)
                            those movies are delightful</p>
                            <p>Bryan White (08:02.4)
                            um so the last last little thing surprising to know on jean siskel put it on his worst of the year list for nineteen eighty two and quite frankly i think that&#39;s a little unfair</p>
                            <p>Dave (08:14.985)
                            but you know what roger ebert liked the movie well okay let&#39;s not say he liked the movie he yeah</p>
                            <p>Bryan White (08:17.46)
                            did he he was he was probably more gentle because i know that that cisco write about bit about sisal all the time but i know that jean is just fucking hated horror movies i know i was like the reason like the whole reason that this movie came to my attention in the first place was because i was watching those in search of darkness documentaries on shutter and this one like by the third the third installment of those there really scrape in the barrel and that&#39;s the longest</p>
                            <p>Dave (08:27.525)
                            yeah those two dudes can suck it they&#39;re fucking terrible i think they&#39;re both dead so</p>
                            <p>Bryan White (08:47.34)
                            one of them of them also there&#39;s some real gems in there like i wouldn&#39;t i wouldn&#39;t have watched this if if it wasn&#39;t her there but like they really made they really made a case for it but so so this what we were talking about earlier about the sort of time line of where this goes like it&#39;s really not clear where in the sort of chronology this lands because the movie canonically because</p>
                            <p>Dave (08:47.405)
                            and by that you mean getting to the good stuff i assume</p>
                            <p>Dave (09:14.565)
                            you mean like canonically or in real life</p>
                            <p>Bryan White (09:17.7)
                            it doesn&#39;t really say because at no point do they really sort of address that anybody lived there before the montell&#39;s did but the story itself is based on amity ville murders i think is the name of the book hans hauser&#39;s book</p>
                            <p>Dave (09:28.905)
                            it&#39;s based on hans holserths book murders and that book is a whole lot of horse ship</p>
                            <p>Bryan White (09:34.72)
                            well well let&#39;s let&#39;s et&#39;s just kind of tell it like it is like all of them are i think here&#39;s a book called the amity ville conspiracy which is really the one that that kind of takes it to task but all the stuff that like that kind of came in the wake of the the j anson book was just like a cash in and and j anson&#39;s book itself was like o cash in but this movie amrdville two is really more about what happened to the defeos and i think i think you know more about</p>
                            <p>Dave (09:39.525)
                            yeah</p>
                            <p>Bryan White (10:04.1)
                            theo case than i do if you want to sort of run it down</p>
                            <p>Dave (10:07.185)
                            prin</p>
                            <p>Dave (10:10.705)
                            i&#39;m going to do it on top my head so apologize if i get things wrong almost certainly will ronald de feo lived in this house in amity ville new york and his was like he was i think twenty early twenties twenty one twenty two someting like that and he lived there as he had three siblings i think they&#39;re pretty close match for this actual family</p>
                            <p>Bryan White (10:27.66)
                            twenty three i think is how old he was</p>
                            <p>Bryan White (10:33.92)
                            three or four but like yeah yeah yeah because right because there&#39;s him who&#39;s the oldest and then there&#39;s his younger sister who&#39;s like eighteen and then there&#39;s two other kids who are like nine and five so there&#39;s a huge disparity</p>
                            <p>Dave (10:40.025)
                            yep</p>
                            <p>Dave (10:49.165)
                            and as i understand that the father in the family was kind of a piece of ship kind of abusive um probably fairly typical father for nineteen seventy three or whatever um and one night run probably high on drugs or on he&#39;s been drinking he goes home grabs a gun walks from one room to the other killing his whole family then goes to a bar afterwards and says hey</p>
                            <p>Dave (11:18.885)
                            they just killed the whole family and they of course called thepolice and then the police go and arrest him and he goes to jail is that the part you want me to tell you tell the whole thing</p>
                            <p>Bryan White (11:29.88)
                            basil i mean we can well i mean i suppose we can kind of walk it out and kind of see see where things mirror because um the way that the way that the murders are carried out in the movie are way different because the details that i know about the story is really kind of part of it that makes it such a such a captivating ghost story is</p>
                            <p>Dave (11:48.965)
                            which the details are probably wrong</p>
                            <p>Bryan White (11:52.5)
                            well i know for a fact that he used um he used a lever action thirty five caliber rifle yeah</p>
                            <p>Dave (11:59.185)
                            marlin rifle i don&#39;t know why i remember the brand but i do</p>
                            <p>Bryan White (12:03.06)
                            um and so i i marlin rifle&#39;s supporter of synomasuicide podcast um the yeah so the thirty five cartridge is like a really fucking big bullet like it&#39;s very loud and if you fire that indoors it&#39;s going to make a lot of noise like a lot of noise nd you&#39;re probably going to defend yourself as well um even using it outside and it&#39;s a very it&#39;s a hunting cartridge you know so like it&#39;s</p>
                            <p>Dave (12:05.225)
                            shout out to marlin rifles</p>
                            <p>Dave (12:30.105)
                            it&#39;s the elephant gun from tremors</p>
                            <p>Bryan White (12:33.3)
                            not exactly but it&#39;s still what oh yeah i mean good god but like that&#39;s that&#39;s kind of the weird thing is they were all found face down shot in the back i think um and</p>
                            <p>Dave (12:35.085)
                            i wish it was i wish it was</p>
                            <p>Dave (12:46.905)
                            which they weren&#39;t i don&#39;t i don&#39;t believe they were there&#39;s photographs i don&#39;t look at them it&#39;s horrifying um</p>
                            <p>Bryan White (12:49.94)
                            okay i&#39;m sure but yeah but just the fact that nobody was really roused as he was going room to room to kill everybody which is such a weird part of the story to me</p>
                            <p>Dave (12:59.685)
                            so joe nicholl&#39;s theory on that is that he killed his parents very quickly like his father was asleep so he never woke up the mother was probably woken up and then killed right afterwards and because he is who he is he kind of went to each room was just like don&#39;t worry about it i&#39;ve got taken care of it so they weren&#39;t alarmed because it&#39;s someone they know and so they think oh there was a noise in the house but don&#39;t worry he&#39;s on it and then he shot them because like one of them was on the floor</p>
                            <p>Bryan White (13:04.76)
                            m yeah</p>
                            <p>Bryan White (13:12.42)
                            sure</p>
                            <p>Bryan White (13:23.76)
                            m</p>
                            <p>Bryan White (13:27.14)
                            that makes a tremendous amount of sense</p>
                            <p>Dave (13:29.725)
                            as though she was getting up the little i think littlest girl as though she was like had gotten out of bed and she was found on the floor he&#39;s pictures of that it&#39;s horrible</p>
                            <p>Bryan White (13:38.76)
                            right so um i think in this movie when we finally kind of get around to it because the murderer this this that all took place way earlier in the movie than i thought it would</p>
                            <p>Dave (13:51.885)
                            oh yeah i was halfway through him like so that&#39;s the end right nope</p>
                            <p>Bryan White (13:55.16)
                            right because it&#39;s it&#39;s it seems like it seems like you know that&#39;s that&#39;s a that&#39;s a pretty pivotal moment to sort of it&#39;s about half way through the movie and then the movie becomes basically another movie uh pretty much so this was written by tommy lie wallace gets the screen credit but it was it was originally written probably in the early drafts by guy named dardanosacetti</p>
                            <p>Dave (14:00.365)
                            it&#39;s about to get weirder</p>
                            <p>Dave (14:07.085)
                            it&#39;s called the exorcist</p>
                            <p>Dave (14:23.825)
                            and you know how you can tell it&#39;s an earlier draft because this movie is as italian as you can get without being an italian movie</p>
                            <p>Bryan White (14:31.02)
                            i guarantee that they that they hired wallace to come in and just sort of smooth it out and make it a lot more american because even after he does it like there are are still a ton of just like there&#39;s a lot of just italian qualities to this movie and it felt it felt really weird one of the reasons that i wanted to talk about this in the first place and and it&#39;s because it was directed by damianodamiani who you know i looked him up and i&#39;m not terribly familiar with him but the fact that he was</p>
                            <p>Dave (14:59.965)
                            i&#39;ve seen one of his movies yeh that&#39;s it</p>
                            <p>Bryan White (15:01.36)
                            yeah um this kind of falls late in his career to like this is like i think he worked up until maybe like nineteen ninety or something like that but i&#39;ve never really seen anything from him but just the fact that they hired just like he straight up italian director who you know it wasn&#39;t ike was making art films he was making like polizioteski and you know like ou know other kind of crime movies and gallo um so that just like hit me as particularly strained</p>
                            <p>Dave (15:05.465)
                            yeah</p>
                            <p>Bryan White (15:30.76)
                            because it&#39;s not sort of the sort of thing that they usually hand off like a sequel to a very profitable movie to i mean like usually they&#39;ll handle the sequels to like lower tier talent but this was just this seems</p>
                            <p>Dave (15:41.725)
                            i think i think this was supposed to be directed by someone else i got the whole the whole time i got the whole feeling like it seems like they had a plan for this movie and then that whole plan changed relate in the game but i&#39;m pretty sure it was supposed to be a different director</p>
                            <p>Bryan White (15:46.8)
                            uh like he fell into it</p>
                            <p>Bryan White (15:55.28)
                            it does have like</p>
                            <p>Bryan White (15:58.2)
                            it&#39;s definitely got a real kind of slap dash frank and stein quality to it but sacetti is yeah like if if you don&#39;t know who dardanasaceti is he wrote for just about everybody in italy fulk lens are genta like everybody and he wrote just he wrote demons and bro demons too he wrote just about every piece of italian horror that&#39;s like worth talking about he&#39;s like the busiest guy in italy like when</p>
                            <p>Dave (16:05.485)
                            yeah</p>
                            <p>Dave (16:17.045)
                            he wrote demons</p>
                            <p>Bryan White (16:27.96)
                            looked up his like his full filmography i couldn&#39;t believe it it&#39;s like every every movie that i&#39;ve seen from italy that i like really really love like he&#39;s</p>
                            <p>Dave (16:38.325)
                            yeah he wrote like every italian movie from nineteen seventy two till nineteen eighty nine</p>
                            <p>Bryan White (16:42.4)
                            it&#39;s crazy it&#39;s crazy so the cast is pretty is pretty wild too and it is they are yeah yeah bert young yeah so that&#39;s that&#39;s bert young everybody knows from rocky he&#39;s the dad there is in the lead is an actor named jack magner he plays sunny and i thought this was a really strange quality about him because i thought he was actually</p>
                            <p>Dave (16:50.165)
                            you&#39;ve got you&#39;ve got uncle polly you&#39;ve got you&#39;ve got the secretary from mommy dearest</p>
                            <p>Bryan White (17:12.4)
                            really good in the movie and he sells the hell out of it but he only acted once more before calling it quits and has small partner in fire starter this is i thought she&#39;d done some stuff before that but this is i think officially the first feature for diane franklin who everybody knows better off dead and incredibly i have to bring this up just cause the movie is so fucked up but she the other movie</p>
                            <p>Dave (17:15.105)
                            so did roger bert apparently</p>
                            <p>Dave (17:32.945)
                            who we all know from better off ted</p>
                            <p>Bryan White (17:42.3)
                            she did this year was a goal in globus like sex comedy called last american virgin</p>
                            <p>Dave (17:48.405)
                            oh yeah with it&#39;s more they like go to</p>
                            <p>Dave (17:54.625)
                            brothel or something i have seen this we rented it</p>
                            <p>Bryan White (17:56.7)
                            yeah i&#39;ve seen it too i don&#39;t i wouldn&#39;t go out of my way to see it again because it&#39;s supposed to be no no there&#39;s like really there&#39;s there&#39;s really nobody like note in it it has a great sound track um but like it&#39;s mostly like the sort of people who would show up in a go and globus movie you know at that time like this is before they were canon and they were starting to get people like chuck nors and stuff it&#39;s supposed to be like a porky&#39;s kind of kind of movie but it is so fucking dark</p>
                            <p>Dave (18:01.225)
                            scott be in this one no he was in the other the other sex comedy</p>
                            <p>Dave (18:22.825)
                            yeah</p>
                            <p>Bryan White (18:26.8)
                            um and she is sort of like the central kind of the girl everybody wants to get with but the way the movie ends is unbelievably fucking dark where like the main character is in love with her but his best friend gets her pregnant so he sells like his stereo to get her an abortion and he thinks that they&#39;re in love and then when he shows up to kind of like yeah he like kind of shows up</p>
                            <p>Dave (18:51.305)
                            this is like a gift of the magi he sells his stereo and she buys him records</p>
                            <p>Bryan White (18:59.88)
                            no no you think like he goes to sort of professor love to her and he finds her in a party making out with his best friend who got a pregnant in the first place and the way the movie ends is him driving off into the darkness crying and yeah and the the credit to roll over that it&#39;s the most fucked up thing in the world but like i swear to god like she&#39;s also like fully frontal nude in the movie and she has got to be one of the most put upon actresses of the eighties um like when better off dead rolled around and she didn&#39;t have a nude scene in it</p>
                            <p>Bryan White (19:29.66)
                            he must have been so fucking relieved um also her and bert young both appear in a movie from twenty eighteen called the amity ville murders as entirely different characters m we also get moses gun from shaft and the shaft cecles love seeing him and just about everything and then james olson guy plays father damski who&#39;s kind of the obsessed priest in the movie and i don&#39;t think i&#39;ve ever actually seen this guy</p>
                            <p>Dave (19:46.825)
                            yeah</p>
                            <p>Bryan White (19:59.7)
                            anything except i found out he&#39;s in oh he&#39;s in andromatitstrain which i&#39;ve i&#39;ve seen but i don&#39;t i don&#39;t really remember it but he&#39;s also in moon zero two which was i&#39;ve only seen because it was on mystery science theater but like most of the cast is is really going overboard</p>
                            <p>Dave (20:01.065)
                            he&#39;s in china and rome or no andromadistrn china one of those two</p>
                            <p>Dave (20:22.985)
                            oh yeah this movie is campe as hell</p>
                            <p>Bryan White (20:24.78)
                            it&#39;s crazy so so we&#39;re going to walk the plot here and just this is this is really really bizarre so have you ever seen this before</p>
                            <p>Dave (20:38.165)
                            i watched this probably i don&#39;t know ten or fifteen years ago and</p>
                            <p>Bryan White (20:42.2)
                            i could swear that we had we&#39;d rented it at some point it felt very familiar but like when i watched it yesterday it was like completely unfamiliar to me but i swear to god we had gotten it at some point in the nineties just like kind of like hard up for something to watch</p>
                            <p>Dave (20:57.925)
                            i mean i don&#39;t it was not that long ago i don&#39;t remember this being as as bankers as it is like there are like sub plots in this that if i had remembered them i would have been like oh right buckle up</p>
                            <p>Bryan White (21:11.44)
                            yeah they really raised some eyebrows yeah and so also like right before i watched it i watched the original andy vill horror because let me tell you when i was when like i think i saw it on t v it was like on like halloween when i was like thirteen or something like that and i watched it and i was funking terrified i remember the moment where kathie looks out the window and sees the eyes in the darkness and funked me up man but then jode</p>
                            <p>Dave (21:20.705)
                            which not a great movie</p>
                            <p>Dave (21:38.945)
                            from the giant pig</p>
                            <p>Bryan White (21:41.96)
                            um i watched it you know i&#39;ve seen it again like since then obviously like a bunch of times and i always like really kind of held it in my heart is like a really good movie and then i watched it last night and it was like it&#39;s like an hour before anything even happens in the movie and i am</p>
                            <p>Dave (21:59.785)
                            and what&#39;s a marco kidder is great in it because marco kidder is great in everything james brown pretty good all in all really handsome couple the problem is everybody in that movie just like everybody in this movie fucking sucks like they&#39;re so unlikable</p>
                            <p>Bryan White (22:04.28)
                            she&#39;s wonderful she&#39;s the best part of the whole movie yep</p>
                            <p>Bryan White (22:10.2)
                            uh</p>
                            <p>Bryan White (22:15.98)
                            yeah yeah the only character in the movie that i truly truly like um is there their daughter only because she has that weird scene where her brothers are like king her with like a fucking with her like with a spider on a like fishing pool but they&#39;re doing it while she&#39;s like giving a stern talking to to her dolls it is a hilarious scene that goes absolutely no er amounts to nothing but it&#39;s just the funniest part of the whole fucking movie</p>
                            <p>Bryan White (22:46.4)
                            but the thing that really stuck out to me is this movie is just a little bit shorter than the original one and it is way tighter like it hits the ground running</p>
                            <p>Dave (22:59.225)
                            oh yeah now it&#39;s your fire out of a cannon from the very beginning</p>
                            <p>Bryan White (23:02.54)
                            it really just like thrusts you into it and it is it is tight and like no time is wasted and it just keeps accelerating until the end when like literally things are like everything is flying it&#39;s like fucking raining in the house it&#39;s it&#39;s it&#39;s nuts but the thing that really stuck out to me was that like for a sequel it&#39;s so rare to find a sequel that is like better than the first one and i truly believe that this is like an objectively better movie than this</p>
                            <p>Dave (23:31.165)
                            i will tell you what i think saves this movie from being just like another kind of throwaway sequel is it&#39;s italian is the fact that it&#39;s so weird and so like it&#39;s america through foreign lens makes it like kind of fun</p>
                            <p>Bryan White (23:31.94)
                            first one</p>
                            <p>Bryan White (23:40.9)
                            that&#39;s</p>
                            <p>Bryan White (23:51.48)
                            maybe and like that&#39;s got to be it because i also because like we were talking about this earlier this wee because like i also watched don&#39;t panic which is the same sort of thing where it&#39;s it&#39;s bending over backwards to convince you that it&#39;s not made in mexico city it&#39;s actually los angeles but like they make he just cannot help but hide the fact that this is like a movie made in mexico with a mexican cast and they&#39;re ll just pretending and so it has just this like bunkers quality of somebody kind of trying to to sort of like translate</p>
                            <p>Bryan White (24:20.9)
                            the culture is that they&#39;re working in without really understanding it but everybody vill too definitely has a lot of the sort of qualities that like i kind of hate it when people talk about fulta movies and that sort of hazy kind of through like looking through gauze quality where everything is really kind of weird and stunted and everybody goes oh it&#39;s dream like it&#39;s dream like yeah it&#39;s like they do that with like jenerolan as well like where his movies are like weird and kind of gauzy and there like it&#39;s very dreamy and sure it&#39;s like i don&#39;t know i think he&#39;s just trying</p>
                            <p>Dave (24:40.525)
                            no they just had no money</p>
                            <p>Bryan White (24:50.86)
                            make a movie on fifty thousand dollars you know but</p>
                            <p>Dave (24:52.965)
                            yeah and he&#39;s also playing the piano to do the sound track with his other hand while holding the camera</p>
                            <p>Bryan White (24:58.8)
                            yeah so swell speaking of music music by layloshrif shiffrin</p>
                            <p>Dave (25:03.005)
                            yea leleshifen really kind of phoned it in on this one</p>
                            <p>Bryan White (25:05.76)
                            i see that&#39;s the thing i have a note here because i was i was wondering if if they just recycled the score from the original i there&#39;s some original stuff in here but there were many times when i was like was this in the last movie</p>
                            <p>Dave (25:15.765)
                            that he yeah that theme is doing doing a lot of the heavy lifting on this one really</p>
                            <p>Bryan White (25:21.34)
                            i mean i got to give it to him like spooky children going la la la over you know haunted piano this is true so yeah so movie starts and the family is moving in and they are they are just so happy and oh i suppose we&#39;ll find out</p>
                            <p>Dave (25:26.085)
                            i mean it&#39;s it&#39;s the omen it&#39;s the same thing</p>
                            <p>Dave (25:38.805)
                            are they though because the second the second i&#39;m like first of all i do not buy uncle polly as a father but i do buy him as a guy from long island</p>
                            <p>Bryan White (25:47.3)
                            well he looks way older oh yeah for sure because i would like say he is the scuszingest movie dad of all time and i love there i love like the only thing that was missing god i wish that they had just like leaned into it his heart but to put it put him in like a stained white like wife beater because like there are scenes where he is like beating the children and the</p>
                            <p>Dave (25:53.145)
                            yeah he he&#39;s real gross he&#39;s like captain lou albano and the girls just want to have fun video</p>
                            <p>Dave (26:11.685)
                            yeah</p>
                            <p>Bryan White (26:17.02)
                            rettening a priest while champing on a cigar</p>
                            <p>Bryan White (26:22.5)
                            and it is like that that whole stereotype has got to have come from this movie you know it&#39;s it&#39;s just it&#39;s so wild</p>
                            <p>Dave (26:27.445)
                            yeah</p>
                            <p>Dave (26:31.965)
                            it&#39;s like they wanted danny i l but they couldn&#39;t get him so they went with uncle polly instead</p>
                            <p>Bryan White (26:34.34)
                            oh i got to wonder like i wonder if they were like hey he&#39;s italian like we&#39;re italian well bring him in or like he served them like maserela or something like that</p>
                            <p>Dave (26:42.885)
                            yeah i was</p>
                            <p>Dave (26:45.345)
                            i was like hey it&#39;s uncle polly and like two seconds later and i was like uncle polly sucks</p>
                            <p>Bryan White (26:49.76)
                            yeah because he really is he really is like he&#39;s way too old to be a father of small children like he&#39;s kind like he&#39;s like balding and like gray hair his entire affect is like frumpy and pissed off like he gets out of the car and he&#39;s so happy to see the kids happy and then there&#39;s that immediate switch where he like you kids get away from the edge of the water there it just turns and then like the rest of the family strolls in we&#39;ve got deloras montelli the mother</p>
                            <p>Bryan White (27:19.82)
                            who co shows up with that&#39;s all i could think of as mommy dearest she shows up and it&#39;s totally appropriate because her performance in this movie is insane like she is hysterical throughout all of it like the very one of the very e first things she does as she goes in and turns on the fosset immediately just like it&#39;s this gout of blood coming out and just shrieking in fear</p>
                            <p>Dave (27:20.365)
                            my dearest</p>
                            <p>Dave (27:32.645)
                            yeah</p>
                            <p>Dave (27:44.625)
                            she is shrieking like there is a were wolf at the door like she is just at twelve</p>
                            <p>Bryan White (27:47.1)
                            yeah her every time she does this because she doesn&#39;t numerous times like but every time she has like that that kind of nervous breakdown like anxiety fit her like her face changes like there were times where she was weak like just wigging out and i&#39;m like is that the same actress like is she wearing make up right now but like they come up and like it&#39;s her and the oldest daughter patricia who boy good god we&#39;re in</p>
                            <p>Bryan White (28:17.2)
                            get into her and then</p>
                            <p>Dave (28:18.238)
                            ah well i&#39;m gonna there&#39;s one thing because this movie i think suffers from the same thing the shining suffers from which is we&#39;re supposed to at least this is my take on it we&#39;re supposed to think that the demonic influence of the house changes their personalities but they all kind of suck from the time they show up so like i don&#39;t know where the demonic inflict the father&#39;s an asshole from the beginning it&#39;s the same thing in the shining where like you&#39;re supposed to watch jack nicholas</p>
                            <p>Bryan White (28:44.48)
                            yeah</p>
                            <p>Dave (28:47.698)
                            and it kind of devolve it&#39;s like jack niclson just does jack nickhleson from the beginning it&#39;s like there&#39;s no stakes here because i don&#39;t give a shit about any of them he seems like an ass hole from the start so i don&#39;t think it&#39;s the house is it</p>
                            <p>Bryan White (28:56.66)
                            right right</p>
                            <p>Bryan White (28:59.94)
                            that&#39;s actually a pretty good question because like well really i mean the children i mean the whole quality of tric is something that sort of emerges a little later on but like her way of being is just a little kind of like weird you know just kind of overacting sunny she&#39;s dian franklin</p>
                            <p>Dave (29:17.098)
                            which one&#39;s trash</p>
                            <p>Dave (29:20.098)
                            oh she&#39;s a sex she&#39;s sex one okay</p>
                            <p>Bryan White (29:21.64)
                            the sex one and then and then sunny shows up he&#39;s the oldest son so he&#39;s the rondefeo junior stand in and like i don&#39;t really get a bad vibe off of him from the beginning he&#39;s just kind of like a cock twenty year old um and his dad just turns on him immediately</p>
                            <p>Dave (29:39.218)
                            echo smoking cigarettes makes you a man</p>
                            <p>Bryan White (29:41.undefined)
                            yeah yeah and you know in rason cars that&#39;s a pretty cool it&#39;s pretty cool car&#39;s got there um ah but like they&#39;re in their moving and everybody&#39;s super happy and the dynamic between the mother and father is really kind of what gives it away where you know uncle polly is is you know he&#39;s just shipped from the beginning like he&#39;s an abusive bad father and the fact that any of those the fact that any of those kids are re even a little bit</p>
                            <p>Dave (29:43.778)
                            yeah</p>
                            <p>Dave (29:47.158)
                            okay us</p>
                            <p>Bryan White (30:10.98)
                            normal is amazing to me like the young kids should just be murdering cats and ship in the neighborhood like that kind of father is what what creates like a serial killer</p>
                            <p>Dave (30:20.438)
                            oh yeah he like he&#39;s a tall he is off the rails from the moment they get there he&#39;s like what do i think an abusive father looks like i&#39;m gonna do that times twelve</p>
                            <p>Bryan White (30:30.68)
                            oh god he really does lean into it and then mommy dearest she&#39;s she&#39;s very she&#39;s very obviously like her body language really kind of sells it is she&#39;s holding it in like you know we&#39;re just your average over yeah yeah yeah and just like</p>
                            <p>Dave (30:33.938)
                            yeah</p>
                            <p>Dave (30:42.238)
                            yes she&#39;s shelly devout</p>
                            <p>Dave (30:45.558)
                            she&#39;s just like put upon woman who is she&#39;s she&#39;s probably every woman from the seventies and eighties like stuck with this sack of ship for a husband and she&#39;s got to make the best of it</p>
                            <p>Bryan White (30:51.02)
                            basically</p>
                            <p>Bryan White (30:56.38)
                            right like she&#39;s to fall out of like the nineteen sixties where like every everybody was just like shoveling value into every housewife so like this is like this is what happens when we when we finally roll up into the eighties and like you just she&#39;s built up a tolerance to it and sweet jesus like because it doesn&#39;t even end there like i mean we have the scene with the sink and then they go down into the basement which is like where you know things was finally get a little spooky um for some reason all the windows</p>
                            <p>Dave (31:25.118)
                            because they hate the ship closet in the basement and it&#39;s always raining in the ship closet in the basement</p>
                            <p>Bryan White (31:26.62)
                            the ship closet which why does anybody even go in there like open the door you can see that it&#39;s raining you can see that there ship everywhere it smells</p>
                            <p>Dave (31:36.498)
                            it&#39;s also not that it&#39;s not the base not part of the basement from the first movie it&#39;s a separate section so they have two ship closets in the basement yeah and that&#39;s white privilege</p>
                            <p>Bryan White (31:40.46)
                            i noticed and right so i noticed this almost yeah so that was something that that</p>
                            <p>Bryan White (31:52.3)
                            that&#39;s something that i noticed also is just because i watched them back to back was um it&#39;s the same it&#39;s the same house it&#39;s the same location the same exterior for oh i didn&#39;t know that yeah because the same house that&#39;s in new jersey that they used for the first one</p>
                            <p>Dave (32:01.978)
                            yeah all the interiors are the same so the exteriors are as well so they</p>
                            <p>Dave (32:10.758)
                            the interior is i believe i think they&#39;re the same</p>
                            <p>Bryan White (32:13.14)
                            because in the in the new el two they have this sort of breeze way between the front door and like the kind of uh you know that that sort of like front room where the steps are in stuff where that is not like the remember in the in the first danbodyville the stairs just go right down to the front door and you open the front door and there&#39;s there&#39;s the yard so it&#39;s definitely the interior of the house is a little different but the red room from the first one was like you know like it was something they had to</p>
                            <p>Bryan White (32:43.02)
                            knock a hole in the wall to find in this one apparently it&#39;s just a crawl space with like a door that props up for easy access to the after world</p>
                            <p>Dave (32:51.878)
                            let&#39;s say you can put your indian bodies in there</p>
                            <p>Bryan White (32:55.26)
                            with the rest of the idiots that good god when that one popped up i was like for funk sake seriously oh i didn&#39;t know that because m yeah so yeah because they go down into the basement and then the moving guy goes into the ship closet gets attacked by bugs because that&#39;s you know just a theme motif that we gotta we got to hold up because it never really comes back after that and then she&#39;s deloras is touched again by the unseen and she again</p>
                            <p>Dave (32:56.498)
                            this is</p>
                            <p>Dave (32:59.598)
                            and that&#39;s not them that&#39;s hans holes or did that yeah</p>
                            <p>Bryan White (33:23.82)
                            just wigs out completely off the off the rails</p>
                            <p>Bryan White (33:30.3)
                            but like yeah so the next the next you know like what follows for a little while is like first first dinner at home which goes completely ape ship like because</p>
                            <p>Dave (33:39.258)
                            it&#39;s like any moment this family interact together it just explodes</p>
                            <p>Bryan White (33:43.48)
                            it&#39;s they are a powder keg and it takes nothing to set them off um because they&#39;re at dinner and then the mirror falls and it just the whole everybody just explodes and for some reason this is sunny&#39;s fault um and just like the fucking violence that erythierupts out of this like nobody gets hit yet but like oh and that&#39;s that&#39;s also this i think this also follows the scene where the ghostly paint brushes like draw that</p>
                            <p>Dave (34:08.738)
                            i think that&#39;s next and i love that part</p>
                            <p>Bryan White (34:10.299)
                            okay so that happens that scene that really moved me because i know that he&#39;s supposed to be a fucking monster and you know uncle polly is just like real piece of ship and they really want you to know that but if you walked into that room and you saw that piece of art</p>
                            <p>Dave (34:25.078)
                            yeah it&#39;s like coca pelly rat dog on the wall clearly painted with some level of skill i might add</p>
                            <p>Bryan White (34:30.46)
                            with with with with a phrase from the you know like an inversion of the bible yeah dishonor the father in cursive and like little like pigs just to kind of i don&#39;t guess to remind you of like manson or something like that and he looked at that yeah and he looked at that and thought well obviously these fucking brats did this</p>
                            <p>Dave (34:36.338)
                            disobey thy father pigs</p>
                            <p>Dave (34:45.258)
                            i think this is all done with a flourish</p>
                            <p>Dave (34:51.038)
                            yeah these two seven year olds did that</p>
                            <p>Bryan White (34:55.56)
                            it was unreal just it&#39;s so crazy of course like this is the first time when things like really kind of get ugly because he know the belt comes off he starts to beat up the kids and then my mommy dearest goes a ham scratches his face and sunny grabs the rifle and like it holds it to holds it to uncle polly&#39;s head in the way that mommy dearest disarms sunny is such a weird shot because she she yeah she like pull like he</p>
                            <p>Dave (35:22.118)
                            here she just walks between the two of them</p>
                            <p>Bryan White (35:25.38)
                            kind of put your hands on the rifle and then just like walks straight at the camera which is such a such a weird like lyrical moment for such a fucked up scene</p>
                            <p>Dave (35:36.898)
                            there&#39;s a bunch of scenes in this movie that every now and then i was like that was a good choice</p>
                            <p>Bryan White (35:41.14)
                            it&#39;s there is actually a lot of them that like i was it is a visually arresting movie at times like most of the time like when it&#39;s family stuff it&#39;s very clostrophobic like all the scenes are very tight and you feel like you&#39;re right in the like the meat with him unbelievably like it is a technically very well made movie um u but like the possession obviously the monster chooses sunny and is upon him almost immediately</p>
                            <p>Dave (36:00.898)
                            yeah</p>
                            <p>Bryan White (36:11.24)
                            uh you know speaking to him through his new fangled walk man headphones um and jack manor is selling the hell out of being possessed um</p>
                            <p>Dave (36:21.578)
                            even when he has the john that john mayor make up on</p>
                            <p>Bryan White (36:25.3)
                            what is oh god yes is that what you when he&#39;s</p>
                            <p>Dave (36:26.878)
                            he because he looks to me like a cross between john mayor and the you remember that william chattin episode of twilight zone with the grain on the plane he&#39;s like like a mash up of those two it&#39;s like if the gradlin from twilight zone and john mayor had a baby it would look like this</p>
                            <p>Bryan White (36:35.12)
                            oh oh god okay so wonne</p>
                            <p>Bryan White (36:42.42)
                            so so it&#39;s yeah so you mean like toward the end when he&#39;s like fully possessed and he sort of reveals himself</p>
                            <p>Dave (36:45.778)
                            yeah yeah but he has shades of john mayor throughout</p>
                            <p>Bryan White (36:50.8)
                            i&#39;ll give you that it&#39;s there so and then there&#39;s the next day like the priest from the like the local parish comes by to to bless the house right and it&#39;s never really explained i kind of wonder if it was like something that was kind of like i don&#39;t know some exposition that kind of ended up on the cutting room floor but like uncle polly for whatever reason just doesn&#39;t care for church you know</p>
                            <p>Dave (36:52.438)
                            yeah</p>
                            <p>Dave (37:20.178)
                            he also keeps calling him priest but in like a really weird way that&#39;s like</p>
                            <p>Bryan White (37:20.4)
                            yeah yeah</p>
                            <p>Bryan White (37:24.32)
                            it&#39;s like yes like like on the i t crowd speak priest</p>
                            <p>Dave (37:29.198)
                            yeah and i don&#39;t know like if that&#39;s like you know how the exorcist she keeps calling him priest or in what&#39;s that salem&#39;s lot when after barlow jumps through the window and then the james mason character keeps calling him he&#39;s like something about your faith priest so i don&#39;t now if it&#39;s like this weird kind of like he&#39;s it&#39;s supposed to reflect the demonic</p>
                            <p>Bryan White (37:37.38)
                            oh yeah oh i wonder yeah</p>
                            <p>Bryan White (37:52.28)
                            oh yeah i mean maybe</p>
                            <p>Dave (37:59.078)
                            fluent</p>
                            <p>Bryan White (38:01.52)
                            maybe maybe it may be it is and that&#39;s the thing that kind of bugs me about it i think it would have been at least a little interesting if they had just taken a second because i mean it just in that moment like that&#39;s so specific that it&#39;s not enough for me that like uncle polly is just a fucking assholebut like and then but the kids of course are going wild because this is like a movie in nineteen eighty two and like everybody is patterning</p>
                            <p>Dave (38:02.498)
                            because he&#39;s also weirdly dismissive but in the weirdest way</p>
                            <p>Bryan White (38:30.3)
                            like the way their children behave after like a stephen spielberg movie where like children are always just always feral maniacs and the what is the sister is like pranking her brother by putting a fucking plastic bag over his head that is really really intense and like i mean maybe it&#39;s just sort of like hey you know you know abuses away a life in this house but like what</p>
                            <p>Dave (38:44.958)
                            bag over his head that seems intense</p>
                            <p>Dave (38:55.498)
                            they feel they feel really genuine as more of the sister because it turns out they actually are but because i watched it the second time and a like these kids look an awful lot of like and i looked it up and there they are but i think you can see it in that scene where they&#39;re very intimate in a way that i don&#39;t think you can fake and like it comes across she&#39;s like patting his head after she puts a bag over his face it&#39;s is</p>
                            <p>Bryan White (39:00.42)
                            i did not know this</p>
                            <p>Bryan White (39:13.58)
                            yeah</p>
                            <p>Bryan White (39:20.76)
                            because like i don&#39;t know there is something so visceral about that kind of like uh like that kind of moved to me i don&#39;t know maybe like maybe it&#39;s like a clostrophobia thing or something but just like the thought of having somebody put a fucking plastic bag over my head and not like not being able to breathe it&#39;s just so terrifying so like eight year old do this to our little brother is so crazy because it&#39;s just like hey kids are playing in here and again and like again uhyouknowoffcomes</p>
                            <p>Dave (39:45.998)
                            they sure are</p>
                            <p>Bryan White (39:50.7)
                            belt and uncle polly starts like whooping on the kids and the priest implores him like don&#39;t don&#39;t hit the child and he&#39;s like hey priest listen you don&#39;t tell me how to how to raise my kids you just do your thing to get out of here</p>
                            <p>Dave (39:55.598)
                            yeah</p>
                            <p>Dave (40:01.278)
                            he&#39;s like i don&#39;t tell you how to raise your kids you don&#39;t tell me what is happening why is he beating children in front of the priest</p>
                            <p>Bryan White (40:04.9)
                            oh</p>
                            <p>Bryan White (40:08.52)
                            yeah because you know that&#39;s how he does that&#39;s yeah that the cigar is the fucking is that just the flourish of the scene that just sells it i wish like god i wish it was like because it&#39;s it&#39;s a fairly new one like he&#39;s been kind of like playing with the cigar since the scene started cause he offers the priest one of them as the scene begins but like god i wished like the one thing that could have just made that better is if it had like a fraid end or it was like smoked</p>
                            <p>Dave (40:11.018)
                            who while smoking a cigar</p>
                            <p>Dave (40:17.698)
                            yeah it has a real like to a quality to it</p>
                            <p>Bryan White (40:38.28)
                            down to a stub you know like like almost like like one of those like those joke ones that you lighted up it blows up and then you know daffy duck&#39;s bill is on the other side of his head so like also in the run up to this like this is the first time that we really get to see trish and sunny interact because they&#39;re they&#39;re checking out their room so fucking dynamic between them</p>
                            <p>Dave (40:59.078)
                            this part is so that actually happens early on that&#39;s right when they move in</p>
                            <p>Bryan White (41:03.24)
                            it&#39;s yeah it&#39;s definitely it something i kind of skipped over accidentally because i definitely want to touch on this it&#39;s it&#39;s almost like the actors had like romantic chemistry like in real life and you know and the script was just written strangely and they played it not as brother and sister but as like two actual like people who are not actors who are like into each other</p>
                            <p>Dave (41:08.178)
                            because the sexual tension between these two</p>
                            <p>Dave (41:27.518)
                            so apparently damian added he wanted this added and specifically just to funk with the audience success</p>
                            <p>Bryan White (41:32.9)
                            right because there&#39;s there&#39;s i&#39;m telling you because i&#39;ve got a note here a little further down that this movie was quite a bit longer when they were tests screening it and</p>
                            <p>Dave (41:44.238)
                            oh and they cut out some some things that i&#39;m glad they cut out</p>
                            <p>Bryan White (41:48.16)
                            yeah apparently the test screening was it was screened with one hundred percent more rape</p>
                            <p>Dave (41:53.278)
                            yeah</p>
                            <p>Bryan White (41:54.9)
                            so but like yeah he added like these details</p>
                            <p>Dave (41:58.258)
                            all shot with this kind of like americans in my right tone</p>
                            <p>Bryan White (42:03.04)
                            yeah so like just this is it&#39;s unbelievable but it really does need to be seen the way that these two behave together because it&#39;s not like i&#39;ve never i&#39;ve known you know like siblings behave a certain way i&#39;ve never known anybody to behave this way like there is a weird sexual tension between</p>
                            <p>Bryan White (42:31.5)
                            brother and sister that like as i&#39;m watching it</p>
                            <p>Dave (42:32.798)
                            that again like the father it all starts before the demon stuff</p>
                            <p>Bryan White (42:37.48)
                            correct like this is then like they&#39;re not even out of the boxes that the house is still like full like it&#39;s still empty yeah</p>
                            <p>Dave (42:41.158)
                            you like they&#39;re already flirting which is not a thing i should say</p>
                            <p>Bryan White (42:48.24)
                            because considering where it goes later that part was i would say it&#39;s just way way more disturbing than like it was very strange and oh god i don&#39;t know i don&#39;t know like why my god absolutely does so so following this sort of like catastrophic experience with the priest everybody except sonny</p>
                            <p>Dave (42:57.098)
                            i was already uncomfortable</p>
                            <p>Dave (43:02.878)
                            and it just goes right off a cliff from there we are off to the fucking races</p>
                            <p>Bryan White (43:16.72)
                            is out and this is when the demon is finally upon him and this is like where it has there&#39;s all these scenes where like he goes down into the basement um and this is the first time that i really kind of felt like i was definitely watching a movie made by somebody who had like worked with with full because sunny goes down to the ship closeted</p>
                            <p>Dave (43:37.058)
                            yeah that has a very beyond field to it</p>
                            <p>Bryan White (43:39.48)
                            it does and and it&#39;s no surprise sacetti had had just worked on the beyond with fulci like a year before this so like he pops it open and there&#39;s this like ghostly light back there and like what when we see it before it&#39;s really it&#39;s just like dirty dirt floor and it&#39;s always raining in there for some reason um but now it has like like the walls turned into like archways and he kind of like</p>
                            <p>Bryan White (44:09.3)
                            s is his way down the down the ship closet in the hall and apropos of nothing there&#39;s just an arm sticking out of the wall in what was i think honestly kind of made my skin crawl like i truly just weird it&#39;s very uncanny</p>
                            <p>Dave (44:23.998)
                            because it&#39;s uncanny it&#39;s like it&#39;s a little too big and it doesn&#39;t seem to be attached to anything and it&#39;s kind of moving</p>
                            <p>Bryan White (44:30.12)
                            it&#39;s literally just yeah because it&#39;s not like it&#39;s an arm sticking out of the wall it&#39;s an arm attached to the wall and definitely there is something about it where the way that the shot is framed like it&#39;s not it feels like it&#39;s warped a little bit you know like something about the way that the picture is that the shot is composed it&#39;s just it&#39;s weird enough to be spooky i really like that scene even though it never really goes anywhere it doesn&#39;t amount to anything but it&#39;s it&#39;s a spooky shot but like this kind of unleash</p>
                            <p>Bryan White (44:59.98)
                            the beast and it follows it follows sunny around the house and there are some really really creative shots with the camera where it kind of follows him around the house and sort of like circling him and kind of like almost like where the monster and it&#39;s surveying him but there&#39;s this really really awesome shot where it&#39;s like following him down the hall and then it kind of goes up and over his head and kind of comes back around on the other side but the camera is like upside down now you know and then it writes itself and then we&#39;re in him he</p>
                            <p>Bryan White (45:29.86)
                            he&#39;s completely possessed um but hey colored gells it&#39;s like a baba movie all of a sudden and he is getting the ship</p>
                            <p>Dave (45:35.918)
                            yeah</p>
                            <p>Dave (45:38.098)
                            and his head starts throbbing like a big gross pumpkin</p>
                            <p>Bryan White (45:40.88)
                            oh yeah because it&#39;s the early eighties we got to have bladder effects but it&#39;s yeah like the fact that like half of his head is about to explode super gross really</p>
                            <p>Dave (45:49.838)
                            and this is when he starts to look like the guy from john mayor in the grand mine</p>
                            <p>Bryan White (45:55.5)
                            really yeah because they really do they raly don&#39;t lean into that but</p>
                            <p>Dave (45:59.258)
                            whoever did the make up in this should not be allowed to do make up in horror movies</p>
                            <p>Bryan White (46:04.28)
                            i don&#39;t know there&#39;s some of the monster effects toward the end i funk and loved um but so now so now</p>
                            <p>Dave (46:10.038)
                            you mean the ones they ripped off from the exorcist</p>
                            <p>Bryan White (46:13.42)
                            oh hey man there&#39;s no rubber monsters in the exorcist but yeah so so sunny is now like fully fully possessed for some reason it&#39;s like pressing on his stomach and it&#39;s like you know kind of sucking his abdomen up into his chest cavity like the sort of expression of being possessed is really really weird to me</p>
                            <p>Dave (46:17.178)
                            that&#39;s true</p>
                            <p>Dave (46:35.078)
                            i believe right before that is the scene where he this might be the night before where he&#39;s getting out of the shower and the shaving mirror like extends out into the hallway and is like kind of letcerously watching him walk down the hall</p>
                            <p>Bryan White (46:49.52)
                            one hundred per cent and completely in character with the rest of the movie like it&#39;s a sleazy sleazy movie but so the family the family returns antri returns and to to find sunny is he inner room or does he sort of come to where i can&#39;t remember how they sort of established a shot right and he&#39;s he&#39;s</p>
                            <p>Dave (46:55.758)
                            yeah</p>
                            <p>Dave (47:08.518)
                            i think he goes into her room and what she says is in their lead up is i think mommy doesn&#39;t want to make love to daddy any more these are two fucking teen agers who are siblings why are they having this conversation</p>
                            <p>Bryan White (47:19.66)
                            yeah</p>
                            <p>Bryan White (47:25.72)
                            this is not i would i would not have this conversation with you this is not this is not something i want to know but i don&#39;t want to even fucking think about that apparently in apparently in an earlier cut like one of the scenes that that that they cut um where this is this is a scene where she&#39;s talking to sonny and she sort of implies that that uncle polly rapes mommy dearest but apparently in an earlier cut that they actually screened he absolutely does</p>
                            <p>Dave (47:31.618)
                            nope</p>
                            <p>Bryan White (47:55.48)
                            it&#39;s you know it&#39;s up there for everybody to see</p>
                            <p>Bryan White (47:59.9)
                            gross right like i mean just the notion in the first place fucked up but like could you imagine those two actors having to sort of sort of act this out jesus christ man like knocking that one out on to the cutting room floor was like a mercy to the rest of us but sunny as</p>
                            <p>Dave (48:19.098)
                            yeah that&#39;s that&#39;s the last bit of salvation you&#39;re going to get</p>
                            <p>Bryan White (48:22.04)
                            yeah so sonny sonny is now sinister you know he&#39;s not like he&#39;s not showing it like there&#39;s a physical quality that kind of comes into play later like where you know he&#39;s always parts of him are constantly pulsing him like his physical appearance starts to change but now it&#39;s like the demon is kind of testing him out and he</p>
                            <p>Bryan White (48:42.78)
                            he&#39;s very obviously kind of coming on to trice and she&#39;s just kind of riding along like it&#39;s the normalest thing in the world</p>
                            <p>Dave (48:48.198)
                            yeah she&#39;s going with it he says he tells her take your shirt off or whatever and she&#39;s like okay just the one time like are you both possessed</p>
                            <p>Bryan White (48:55.26)
                            ah take just one time what so here&#39;s the thing sonny is possessed by a demon that which seeks to do evil what&#39;s her excuse man this is</p>
                            <p>Dave (49:04.318)
                            yeah</p>
                            <p>Dave (49:06.958)
                            yes she&#39;s a woman apparently that&#39;s my note just says this movie is so italian</p>
                            <p>Bryan White (49:15.06)
                            oh man because yeah because ladies do be like that i suppose but like the fact that that that like there were not red flags going off just in the run up to the to the to him telling her to take her nightgown off is so messed up because not only does she do it she&#39;s it&#39;s a it&#39;s a playful thing you know it&#39;s just it&#39;s just brother and sister having a good time hanging out and then they have sex</p>
                            <p>Dave (49:19.118)
                            ya wow</p>
                            <p>Dave (49:32.938)
                            and then she&#39;s just sitting there like this is just a thing we do sometimes</p>
                            <p>Dave (49:39.918)
                            what</p>
                            <p>Dave (49:44.038)
                            yeah</p>
                            <p>Bryan White (49:44.92)
                            and it&#39;s not like it&#39;s not like he&#39;s forcing herself like this is a consentual act huh</p>
                            <p>Dave (49:50.898)
                            now look i don&#39;t want to king shame but i think in this case i think in this case we have to</p>
                            <p>Bryan White (49:52.9)
                            oh</p>
                            <p>Bryan White (49:58.06)
                            it is good god it is unbelievable like there&#39;s just the fact that this happens because what follows is she goes to confession and there is there&#39;s this suggestion like i mean she&#39;s acting like she&#39;s acting like she&#39;s guilty she&#39;s got something to confess but that is</p>
                            <p>Dave (50:16.198)
                            she says she says she went all the way with her friend</p>
                            <p>Bryan White (50:20.08)
                            with her friends so she&#39;s not even being honest about this to the priest</p>
                            <p>Dave (50:22.858)
                            like there&#39;s an ellipsis in the closed captioning</p>
                            <p>Bryan White (50:26.76)
                            no but</p>
                            <p>Dave (50:27.378)
                            yeah</p>
                            <p>Bryan White (50:31.62)
                            so yeah and and like the priest is like you know you&#39;ll feel better and you can when you confess like you you don&#39;t love this person like it&#39;s it&#39;s the whole thing he&#39;s trying to sort of like walker through to like acts of contrition right and she she runs out but later at the birthday party scene um she she confronts sunny because he&#39;s like being cold toward her and she asks him do you feel guilty</p>
                            <p>Bryan White (51:01.52)
                            because i don&#39;t what the fuck is happening in here so like maybe this is like the the demon&#39;s effect but like as far as we can tell like she is completely untouched by the sort of sinister forces in this house</p>
                            <p>Dave (51:03.538)
                            yeah</p>
                            <p>Dave (51:19.658)
                            well and she tells the priest that he does it to hurt god or something like that and so it&#39;s like so she knows that he&#39;s possessed i guess</p>
                            <p>Bryan White (51:23.16)
                            to hurt god that has</p>
                            <p>Bryan White (51:27.56)
                            i guess that struck me as a scene like a line that was definitely either either she did it on the day or they left it in from one of like sacetti&#39;s earlier drafts because it definitely feels like i feel like i&#39;ve heard that line in other like italian horror movies it feels very much like because that&#39;s you know like you know the fucking you know the vaticans in italy you know so so they&#39;ve got like an undeniable catholic influence over him um and whenever they kind of go at this like like pulci kind of does this whenever he&#39;s trying</p>
                            <p>Bryan White (51:57.58)
                            critical of the church m it felt like such a like a deliberate line because then like in the very next scene she&#39;s walking it back it&#39;s so fucking weird um but then you know so with his birthday party and um uh it&#39;s sort of braided up to the demon is goading him there&#39;s that really long awkward</p>
                            <p>Dave (52:18.798)
                            there&#39;s there&#39;s more there&#39;s more sex scenes at the birthday party with the hugging the lingering and the hugging yep</p>
                            <p>Bryan White (52:23.88)
                            oh ship that&#39;s right right because because that&#39;s when mommy dearest noticed that and we get a little bit more of that peddled to the metal acting that she&#39;s that she&#39;s really kind of carving out for herself in this um</p>
                            <p>Dave (52:28.298)
                            yeah</p>
                            <p>Dave (52:36.618)
                            it&#39;s like everyone in this movie is giving you all that they can give you while also doing the absolute least they can do</p>
                            <p>Bryan White (52:42.8)
                            my note is i would love to act in a movie like this everyone is swinging from the rafters because so like yeah so they have that really long sort of scene where you know they the family is sort of gathered on the other side of the table sort of staring at sunny while he stares at them and the demon is like look at those pigs you know but like it&#39;s not like it&#39;s a pause or like i think oh yeah for sure</p>
                            <p>Dave (52:48.498)
                            yeah</p>
                            <p>Dave (53:09.658)
                            that was tommy lee wallace&#39;s line that was his stuff</p>
                            <p>Bryan White (53:12.88)
                            oh that&#39;s it&#39;s it&#39;s solid gold like everything whenever the demon is hissing to him i&#39;m like but it&#39;s the thing that really sticks out to me is it&#39;s not like they posit or they slow it down like it&#39;s just this weird pregnant pause in the acting where everybody is just awkwardly standing on the other side of the table staring at him</p>
                            <p>Dave (53:16.938)
                            yeah</p>
                            <p>Dave (53:31.258)
                            it&#39;s like every scene in this movie is meant to make you wicked uncomfortable</p>
                            <p>Bryan White (53:35.78)
                            mission accomplished maybe that&#39;s why</p>
                            <p>Dave (53:36.918)
                            even these like benin just sort of like hey it&#39;s a fucking birthday party that&#39;s like okay then why are you all looking at each other like you&#39;re all either about to fuck each other or kill each other and in this case it is both</p>
                            <p>Bryan White (53:47.78)
                            oh my god because then then all of a sudden like this like mob of people just storms through the front door and you know breaks the tension but then yeah so mommy dearest you know corners trichand she&#39;s like what have you been doing with your brother you know and then like it kind of ushers us in</p>
                            <p>Dave (54:06.298)
                            it was a weird leap to make from just that one weird hug like yeah okay it was it was awkward but i wouldn&#39;t i wouldn&#39;t jump right to that</p>
                            <p>Bryan White (54:09.64)
                            look like their their body language is their body language is really fucked up but like i don&#39;t think even in my like like even in my most cynical that like i would be like well obviously they&#39;re fucking</p>
                            <p>Dave (54:24.018)
                            yeah i mean i would say it but i wouldn&#39;t believe it</p>
                            <p>Bryan White (54:27.58)
                            because like i mean like she comes out and she&#39;s like seething and it&#39;s like venomous it&#39;s such a crazy scene um but yeah so this kind of ushers us into kind of like the big like second act swing when</p>
                            <p>Dave (54:41.578)
                            and this is when the priest has to get the permission for the exercistant scared german guy will not give him permission for the exercise</p>
                            <p>Bryan White (54:49.04)
                            oh god right yeah because he also he eventually does come around to do the blessing and right he&#39;s got the little sprinkler thing that the holy water and the blood comes out of it yeah the blood comes out of it and mommy dearest goes insane it&#39;s like she&#39;s in scanners it&#39;s like the look that was one of those moments where i was like is that the same actress because like her physical appearance changes when she&#39;s in those like just unhinged moments it&#39;s so weird and the screen</p>
                            <p>Dave (54:57.138)
                            but then he tries to that&#39;s when they but</p>
                            <p>Dave (55:02.458)
                            her head explodes</p>
                            <p>Dave (55:06.818)
                            yeah</p>
                            <p>Dave (55:18.398)
                            yes she is she looks like something out of like a david lynch movie just in those moments like everything is over overblown everything acting the lighting everything</p>
                            <p>Bryan White (55:19.22)
                            is</p>
                            <p>Bryan White (55:23.72)
                            yeah she&#39;s over lit</p>
                            <p>Bryan White (55:29.12)
                            she&#39;s a little like the camera is a little too close on her she&#39;s over lit and the scene the scream shot goes on way too long like you could have cut it up and be like okay so she&#39;s screaming and this is what she&#39;s screaming at no you get to you see her scream the whole time and then she draws a breath and keeps going like</p>
                            <p>Dave (55:48.058)
                            yeah and you know that&#39;s another one of those scenes where i&#39;m like hey i like it go on you for that</p>
                            <p>Bryan White (55:53.22)
                            yeah yeah so like this is like this is the moment so it&#39;s night time and the demon is has woken sunny up and it&#39;s time to get the rifle he goes up and he begins to stalks around the house and shoots everybody dead and it is so satisfying</p>
                            <p>Dave (56:11.758)
                            there&#39;s a moment though there&#39;s a moment with the little kid in girl from better off dead and little boy are kind of across the hall from each other looking at each other that scenes kind of i was like okay i&#39;m into this too like this what you&#39;re doing here and and then it ends but i need just sort of appears behind him</p>
                            <p>Bryan White (56:21.24)
                            that&#39;s right becase they&#39;re the last to die</p>
                            <p>Bryan White (56:27.64)
                            it&#39;s yeah yeah well the death of uncle polly is so fucking satisfying like when that happens i&#39;m like oh good like he had to come in</p>
                            <p>Dave (56:33.418)
                            yeah</p>
                            <p>Dave (56:38.118)
                            billy they kind of all do like you have a sexy sister you got mommy dearest who is she&#39;s basically just there to shriek and then you&#39;ve got uncle polly who sank a ship</p>
                            <p>Bryan White (56:40.88)
                            yeah</p>
                            <p>Bryan White (56:47.96)
                            yeah it was a mercy it was a mercy absolute second ship mommy dares was a mercy killing the poor children like i mean but those kids were going to grow up to and murder somebody with a plastic bag it is this is the sole movie is just like everybody&#39;s behavior like you can track out like the escalation where each one of them is going to go like uncle paul is going eventually murder mommy dearest she is going to go like just eventually just have a complete psychotic break</p>
                            <p>Dave (56:57.578)
                            yeah</p>
                            <p>Dave (57:00.158)
                            i mean they all would have ended up like ronald de feo</p>
                            <p>Dave (57:14.018)
                            yep</p>
                            <p>Bryan White (57:18.1)
                            um rich is a like if this is if this is where we&#39;re starting off where like like consensual incest is you know it is you know square one where the fuck are we going from there you know and the children were going to be murderers because what else you know what kind of what have they got going on</p>
                            <p>Dave (57:28.158)
                            non demonic</p>
                            <p>Dave (57:36.538)
                            you did get the part where the priest goes into sunny bedroom and they have that really awkward exchange because he&#39;s like hiding from him what the fuck does it&#39;s nice territory mean is that is that goin definitely it has like let&#39;s wrap energy</p>
                            <p>Bryan White (57:41.54)
                            nice nice territory yeah</p>
                            <p>Bryan White (57:47.34)
                            it&#39;s nice territory you know it&#39;s like he&#39;s being a cool guy this</p>
                            <p>Bryan White (57:54.84)
                            yeah he&#39;s he&#39;s like the cool youth pastor like like it this is it&#39;s a cool room it&#39;s nice nice territory</p>
                            <p>Dave (57:57.258)
                            okay i don&#39;t like that because he already he already reads really gay and so</p>
                            <p>Bryan White (58:05.undefined)
                            his that that priest and his buddy the other priest that andrew prin plays like i definitely like their friendship</p>
                            <p>Dave (58:14.698)
                            and andrew prin i think is gay i think the other one i think james olson is too but i&#39;m pretty sure andrew prin is because that that one i was just like waiter there like a couple aretheygoing camping is a couple that&#39;s very progressive</p>
                            <p>Bryan White (58:16.92)
                            i book</p>
                            <p>Bryan White (58:24.48)
                            it&#39;s right i mean it could be it could be but hey men you know othocellebacy but like</p>
                            <p>Dave (58:31.578)
                            it&#39;s also nineteen ightytwo and it&#39;s made by italians so i think the answer is no as far as they know no</p>
                            <p>Bryan White (58:35.6)
                            yeah but yes as soon as andrew brian showed up i was like oh shit it&#39;s andrew prone i love that guy like i&#39;ve ever seen simon king of the witches it&#39;s such a great movie and the whole thing just floats on his carisma but yeah like that&#39;s the whole thing like he is in the middle of so so father damski is his name and he is in the middle i haven&#39;t even mentioned him because he&#39;s like the one of the most least compelling characters in the entire movie even though like up to this point he&#39;s been in</p>
                            <p>Dave (58:41.198)
                            yeah</p>
                            <p>Dave (58:43.938)
                            yes</p>
                            <p>Bryan White (59:05.42)
                            a third of it</p>
                            <p>Dave (59:07.098)
                            he&#39;s a pretty flimsy stereotype</p>
                            <p>Bryan White (59:09.16)
                            yeah he he is</p>
                            <p>Dave (59:10.998)
                            he&#39;s like father maren but without the sort of grave tas</p>
                            <p>Bryan White (59:16.62)
                            yeah um but man the church formal what a pain of the ass man like they just want to slimy him at every at every turn</p>
                            <p>Dave (59:18.338)
                            it&#39;s like if i were playing father maren</p>
                            <p>Dave (59:25.598)
                            well that&#39;s because that scary german guy from monster squad the chancellor as i saw i was like ships</p>
                            <p>Bryan White (59:28.38)
                            that&#39;s that&#39;s right that&#39;s right i knew i didn&#39;t that didn&#39;t immediately stretch stick out to me when i saw him i was like oh ship it&#39;s the doctor from done but like i don&#39;t even know that guy&#39;s name but like for the fact that he&#39;s to me he&#39;s the doctor from done and he&#39;s scary german guy to ho kind of says a thing or two about him he really does leave an impression but he&#39;s got a look about him he definitely is he&#39;s got a look about him that lie immediately i saw him and i was like i know that guy</p>
                            <p>Dave (59:49.058)
                            he is a character rector</p>
                            <p>Bryan White (59:56.9)
                            but uh yeah so the murder is done sonny is arrested in the morning the priest shows up and you know kind of</p>
                            <p>Dave (01:00:04.018)
                            the best part about that is i swear to god the line i&#39;m his priest gets in any door he just says it over and over like why do they just keep letting this guy in</p>
                            <p>Bryan White (01:00:10.32)
                            no ship like could he just</p>
                            <p>Bryan White (01:00:14.08)
                            but the cops are also like he out of the way he&#39;s their priest</p>
                            <p>Dave (01:00:17.658)
                            yeah and then the cops are also like yeah calm this guy down the one of the cops like what is happening</p>
                            <p>Bryan White (01:00:21.22)
                            it&#39;s definitely like it&#39;s definitely like a get out of jail free car but then again like like the way that like the like american intelligence in particular like worked with with religion in the eighties is kind of weird where like they got in bed with like the knights of malta and the like the eastern orthodox church with the wandering bishops mostly just because priests can just kind of go around and just do whatever the un they want yeah so hey i&#39;m i&#39;m gonna try that next time i see like a crime scene i&#39;m goin no not on do that</p>
                            <p>Dave (01:00:44.898)
                            it just opens any door</p>
                            <p>Dave (01:00:49.038)
                            i&#39;m gonna do it like hey hey don&#39;t worry about it i&#39;m a priest and they&#39;re gonna they&#39;re on look at me an ter gonna be like yeah you are obviously</p>
                            <p>Bryan White (01:00:53.12)
                            i&#39;m his priest ud be like</p>
                            <p>Bryan White (01:00:57.5)
                            you got to ave the collar i believe because i believe he&#39;s wearing the collar and stuff in this in the moment right yeah</p>
                            <p>Dave (01:01:02.338)
                            he is i still don&#39;t think that that should be enough</p>
                            <p>Bryan White (01:01:05.72)
                            you should have like credentials official</p>
                            <p>Dave (01:01:07.538)
                            yeah i want to like just look at his license or something i don&#39;t know</p>
                            <p>Bryan White (01:01:10.9)
                            like a right because i could of just gone to spirit halloween and bought a fucking priest collar but yeah so he goes in he&#39;s</p>
                            <p>Dave (01:01:13.658)
                            yeah</p>
                            <p>Dave (01:01:19.018)
                            there&#39;s also like no explanation for why he&#39;s so over invested in this family who he just met like two weeks earlier</p>
                            <p>Bryan White (01:01:26.76)
                            i would okay i would say that it&#39;s it&#39;s kind of communicated in the fact that he&#39;s been there he&#39;s seen what the fucking house is like and he&#39;s like these people need jesus very badly</p>
                            <p>Dave (01:01:40.698)
                            well good thing that you have moses gunn the most credulous police detective in the world because he&#39;s like oh demons yehi&#39;snot one of those you take this kid out of here</p>
                            <p>Bryan White (01:01:48.58)
                            oh my god he&#39;s like oh yeah he&#39;s oh yeah i saw them a yeah demons i saw some of them in porto rico oh man because that scene in that scene in the in the prison cell so so father damsky goes to visit sunny and he&#39;s escorted to the prison cell with fucking moses gun bumpy himself and they have that really awesome exchange where he&#39;s like kiss the crucifix i was like that such</p>
                            <p>Dave (01:01:56.618)
                            yeah</p>
                            <p>Dave (01:02:18.438)
                            that whole scene is great</p>
                            <p>Bryan White (01:02:18.46)
                            arley bits such a narlyline that he licks his hand and it like cizzles so great because like this is really this is really kind of the turning point where the movie just goes fucking possirk right because then they have they have the arraignment scene where the fucking the lawyer with a dead face like just a regular like this is he does this all the time he enters a plea of not guilty by demonic possession</p>
                            <p>Dave (01:02:29.758)
                            this is when it becomes the exorcist</p>
                            <p>Dave (01:02:47.038)
                            which is interesting because it&#39;s i think about seven six or seven years later when they actually did that</p>
                            <p>Bryan White (01:02:53.18)
                            well here&#39;s the thing actually i looked this up because it sounded familiar um there was one of the fucking conjuring sequels that the so that was nineteen eighty one that happened a year before this and they actually did enter a plea of demonic possession because ed and fucking</p>
                            <p>Dave (01:03:01.338)
                            yeah that&#39;s the one i think it was eighty six or eighty seven</p>
                            <p>Dave (01:03:06.858)
                            okay</p>
                            <p>Dave (01:03:10.438)
                            yeah and the judge said the judge said get the fuck out of here with that</p>
                            <p>Bryan White (01:03:15.1)
                            yeah because because also ed and loraine warren were also involved in that case and they&#39;re the ones who are like he&#39;s demon possessed you tell that those mother suckers have done immeasurable damage to our society and it is a shame that they both got to live full lives like this because this whole thing like basically we we have them to thank for all forty two army ville movies like if they had</p>
                            <p>Dave (01:03:18.838)
                            yep yep</p>
                            <p>Dave (01:03:22.538)
                            spiritualist grifters one and all</p>
                            <p>Dave (01:03:29.838)
                            yep</p>
                            <p>Dave (01:03:34.458)
                            yeah</p>
                            <p>Bryan White (01:03:44.94)
                            or come around this is true like the fact that they are that they are they have their own like franchise and are portrayed is like king super heroes is so weird to me and for some reason i&#39;ve seen all of those movies i don&#39;t know why i fucking hate them um</p>
                            <p>Dave (01:03:46.298)
                            and the james wane verse</p>
                            <p>Dave (01:04:01.478)
                            well if you want there&#39;s a television version of the last one the demon the demon made me do it one there&#39;s a t v version of that from the mid eighties maybe staring kevin bacon and who was who was phyllis on matamore</p>
                            <p>Bryan White (01:04:07.88)
                            yeah</p>
                            <p>Bryan White (01:04:19.6)
                            oh</p>
                            <p>Dave (01:04:20.958)
                            what is her name deloras now clrusleachman god shouldn&#39;t i shouldn&#39;t have struggled with that one</p>
                            <p>Bryan White (01:04:24.7)
                            clara leechman that yeah that that was just it was on the tip of tongue i could hear it</p>
                            <p>Dave (01:04:29.278)
                            um yeah there&#39;s a t v version of that and it is so much better there&#39;s also an episode of a haunting about that one and then you can see how seriously ridiculous that story is and it&#39;s like oh he just killed him</p>
                            <p>Bryan White (01:04:31.58)
                            that sounds delightful</p>
                            <p>Bryan White (01:04:39.58)
                            well like the truth of it yeah like the truth of the story there&#39;s like nothing like like para normal about it it&#39;s just there was a guy</p>
                            <p>Dave (01:04:45.278)
                            yeah</p>
                            <p>Dave (01:04:47.918)
                            this guy was like a menacing threat to these children and his girl friend so he killed him i&#39;m cool with that you don&#39;t have to throw a demon in you do if you&#39;re ed warren but</p>
                            <p>Bryan White (01:04:52.undefined)
                            yeah yeah</p>
                            <p>Bryan White (01:04:56.12)
                            right yeah those two those mother suckers like hey man he sees dollar signs on every gravestone</p>
                            <p>Dave (01:05:04.738)
                            any chance to promote white hetero sexual christian love he will get out there and make as much money as he can do it</p>
                            <p>Bryan White (01:05:11.2)
                            fuck me man he like that like um there&#39;s we should definitely do an episode about everything that they&#39;ve been involved in and i would love to get have a couple of friends who do like a lot of para normal stuff they have the the greggandena new car they do the the haunted objects podcast and bunch of other things and they have very very strong thoughts about the warrens i would love to get them on to talk about that</p>
                            <p>Bryan White (01:05:41.02)
                            ship because those those two like we&#39;ve got some great entertainment out of it but like the cost is so fucking high they are the absolute worst but like when i saw that they were also they were also like they had a hand in this one as well because they&#39;re not really in either of the movies but they&#39;re like their presence can sort of be felt like in the first ambodyville they&#39;re kind of they&#39;re kind of insinuated into it by george lutsslikepar</p>
                            <p>Bryan White (01:06:11.04)
                            her and is like weird like astrologer girl friend the girl who&#39;s like it&#39;s the passage duel like that scene because she&#39;s supposed to be loraine warren who was like yeah because that was kind of loraine thing as she because that that room that they found in the basement lie that was real like that was a hidden room that had just been walled over because there was nothing there it was just an empty room it was like there was no reason to have it or whatever it was just it was just there and caused a dram</p>
                            <p>Dave (01:06:20.658)
                            is she</p>
                            <p>Dave (01:06:33.338)
                            it&#39;s probably structurally unsafe</p>
                            <p>Bryan White (01:06:41.76)
                            but like she saw it and was like well demons moved through here because that&#39;s what she does as not to get off track but like that&#39;s that&#39;s them and the story continues to move on where father damski eventually conine convinces moses gun that he&#39;s got to take the boy out of here and have him have him exercised yeah here&#39;s my gun for some reason he&#39;s not</p>
                            <p>Dave (01:07:04.258)
                            and moses gun just moses gun just says okay let me help you with that</p>
                            <p>Bryan White (01:07:10.96)
                            seen anything to make him think like</p>
                            <p>Dave (01:07:13.098)
                            but he just gives this guy a gun you know i i&#39;m a priest gets in anywhere</p>
                            <p>Bryan White (01:07:16.56)
                            yep my god you can do fucking anything i mean jesus christ those guys well i mean it kind of speaks for themselves but like they&#39;ve been abusing power for quite some time</p>
                            <p>Bryan White (01:07:27.44)
                            but so so yeah does he does he busts sunny out of out of the hospital he&#39;s under like observation the leaves a cop posted up there and moss just says he don&#39;t you go over there and the cops like yeah yeah you go over there for a minute yeah sure boss he goes down the hall and disappears and then he sets it up so that they can escape and they do and he he tries to take him back to the church</p>
                            <p>Dave (01:07:42.378)
                            yeah it just like points down the hall and like that seems on brand for them</p>
                            <p>Dave (01:07:56.418)
                            wait my other question is why does moses gun have keys to the hospital and also why does he how does he know to give him directions to get out of the hospital</p>
                            <p>Bryan White (01:08:00.88)
                            that&#39;s a very good question well i mean</p>
                            <p>Bryan White (01:08:06.8)
                            he knows that hospital well you see he&#39;s probably been there a lot</p>
                            <p>Dave (01:08:10.338)
                            i like this this guy so he believes in demons really nelly and he has keys to the hospital and he&#39;s just like givin a priest the gun i i&#39;m not a hundred percent a cab here but i do have to question the validity of the police in this scenario</p>
                            <p>Bryan White (01:08:30.04)
                            they are they&#39;re really not doing their job it&#39;s it&#39;s they&#39;re like he really like the only cops that we ever really interact with are him and he is just all in like there&#39;s no you could have told him that there was a fucking u f o landing in the back yard at the house on ocean ave and he would have been like you i&#39;ve seen that too in porto rico yeah like it&#39;s unbelievable like he just like you know clearly like he struggles with it a little bit but like you know</p>
                            <p>Dave (01:08:48.978)
                            yeah he oudavebelike great let&#39;s go we got to get out of here</p>
                            <p>Bryan White (01:09:00.14)
                            he&#39;s a believer apparently um so yeah father enki takes him back to the church tries to get him into the church and sonny you know fully in the throws of the demon like freaks out and backs away and there is this massive eruption of flame in the street totally awesome effect</p>
                            <p>Bryan White (01:09:19.5)
                            you can just do that magically i&#39;m cool with that yeah um so yeahdamsky very rightly imagines that sonny goes back to his house so he goes back to his house um he&#39;s full john mayer but first we have to go through the house starting in the basement so we get to look at the ship</p>
                            <p>Dave (01:09:22.938)
                            again he&#39;s a demon</p>
                            <p>Dave (01:09:33.698)
                            and he is full john mayor at this</p>
                            <p>Dave (01:09:41.538)
                            now first question why is it raining in the entire house now</p>
                            <p>Bryan White (01:09:44.undefined)
                            why is it raining it&#39;s like fucking pouring in that house like it is crazy pouring in that house um but that was like one of the effects that i loved about it like there&#39;s absolutely no reason for any of this to happen like they could have just like you know started a fan up and blown the you know the boat all the curtains around is something like that sort of but they are all in like the windows are all nailed up and boarded and there&#39;s like it&#39;s it is it&#39;s like a torrential downpour in the house it goes into the</p>
                            <p>Dave (01:10:04.238)
                            they are going all in</p>
                            <p>Bryan White (01:10:13.76)
                            basement in the ship closet opens up and there&#39;s like people starting to come out of it</p>
                            <p>Dave (01:10:18.158)
                            and my note just says oh no indians</p>
                            <p>Bryan White (01:10:22.98)
                            mine actually says oh good the old indian burial ground trope because there is also there is that weird moment sort of after the murders have have taken place but like before a damski has gone to visit sunny he&#39;s just like standing in the in the snow like like staring at the house with this haunted expression and the woman comes as shows up she&#39;s like do you need a ride father and he&#39;s like</p>
                            <p>Dave (01:10:26.158)
                            m this fucking trope</p>
                            <p>Dave (01:10:43.538)
                            oh yeah and the woman shows up</p>
                            <p>Dave (01:10:47.858)
                            i&#39;m like what the fuck is going on in this town and he was like well i&#39;m a priest</p>
                            <p>Bryan White (01:10:50.74)
                            yeah he&#39;s like yes i would and she also just she also just happens to know why that house is so messed up</p>
                            <p>Dave (01:10:57.298)
                            also it&#39;s really late at night but they&#39;re going to the town hall to go through the records and there&#39;s other people there browsing like it&#39;s a library</p>
                            <p>Bryan White (01:11:03.12)
                            there&#39;s a ship load of people there it&#39;s a god&#39;s nuts it&#39;s such a funny scene like it&#39;s a very clumsy piece of exposition but like i love it yeah but yeah so like i guess that scene in the basement i think was supposed to be a little bit longer um and there&#39;s actually like he blesses the ghosts i thought it was cool i kind of wish that there was a little bit more like where they</p>
                            <p>Dave (01:11:12.458)
                            i love it we&#39;re talking about it now i mean it works</p>
                            <p>Dave (01:11:28.918)
                            so what year is polter guys to</p>
                            <p>Bryan White (01:11:31.2)
                            polter guys two is eighty six or eighty seven i want to say</p>
                            <p>Dave (01:11:34.678)
                            so because in in roger bert&#39;s review he talks about this moment and brings up the indian burial ground and makes a reference to pulterguyst but there is no indian burial ground in pultergason that&#39;s polter guys too and it&#39;s actually not even in polter guys too he&#39;s just a magical native man and pulterguys too</p>
                            <p>Bryan White (01:11:48.08)
                            no right because pulterguysed it&#39;s just</p>
                            <p>Bryan White (01:11:55.82)
                            that&#39;s right yeah i know because the the pultrnpoler guys it&#39;s just that they yeah i know what you mean yeaheahnpultr guys they just built it on top of an actual cemetery yeah apparently yeah apparently like apparently that like indian barrel ground trope was just so fucking pervasive that like yeah like even i wonder where that first came from</p>
                            <p>Dave (01:11:57.078)
                            but it&#39;s just bodies it&#39;s just regular bodies not that native people are not regular but you know what i mean</p>
                            <p>Dave (01:12:07.538)
                            yeah because they moved the stones but they didn&#39;t move the bodies quite famously if they didn&#39;t move the bodies</p>
                            <p>Dave (01:12:18.238)
                            it&#39;s quite old</p>
                            <p>Dave (01:12:21.978)
                            it comes from what&#39;s house of seven gables who&#39;s that</p>
                            <p>Dave (01:12:27.778)
                            not johnson swift the in massachusetts</p>
                            <p>Bryan White (01:12:32.28)
                            i should i don&#39;t know i should know that but you know that was high school</p>
                            <p>Dave (01:12:35.818)
                            it was her melville&#39;s neighbor</p>
                            <p>Dave (01:12:40.658)
                            is obsessed with him bob do can&#39;t remember h that&#39;s where it comes from</p>
                            <p>Bryan White (01:12:44.32)
                            bob dull it is nathaniel hawthorn yeah uh boy but like as we&#39;re starting to reach this this point this is like a really it really kind of dawned on me that that there is no there is no sort of genera or genre trope that italy wouldn&#39;t exploit into sort of like a fucking menagerie of rip off like mad max yeah like mad</p>
                            <p>Dave (01:13:06.238)
                            yeah</p>
                            <p>Dave (01:13:10.278)
                            oh yeah they do it with a plumb and i love it every time this one i&#39;m not so hot on this one</p>
                            <p>Bryan White (01:13:15.34)
                            like mad max like was the movie that set like sailed a million ships like it&#39;s crazy how many like really crappy post nu movies they made but like the exorcist did</p>
                            <p>Dave (01:13:23.578)
                            and i love them all and you have dartanosaceti to thank for at least seventy percent of them</p>
                            <p>Bryan White (01:13:29.12)
                            oh shit for sure but like the exorcist did huge business he you know rosemary&#39;s baby the omen up to this point i think men two had been out at this point and then this and then the original amity ville horror was like a massive success but like the one gap in the sort of italian exploitation machine that i really think is kind of weird is that there&#39;s not really a lot of possession movies nor are there a lot of haunted house movies like it</p>
                            <p>Dave (01:13:56.098)
                            i think it&#39;s because you have there&#39;s only two ways you can pull those off one of them is you have a lot of money for special effects or you have to have a cast that is so good and with a script that is so well written that they can build tension and suspense italian exploitation has neither of those things</p>
                            <p>Bryan White (01:14:12.78)
                            i suppose i mean because it kind of struck me as you don&#39;t need a lot of money to make a haunted house movie because it&#39;s like it hinges so heavily on like what you don&#39;t see well that&#39;s that&#39;s that&#39;s definitely something</p>
                            <p>Dave (01:14:21.878)
                            but you have to have talent then not that they don&#39;t have talent but like you have to be really invested it&#39;s why the haunting works as a movie with it obviously doesn&#39;t have much of a budget in the sixties because</p>
                            <p>Bryan White (01:14:28.66)
                            yeah yeah</p>
                            <p>Bryan White (01:14:35.06)
                            would be interesting to sort of see it what the actual numbers for like these big satan movies had for like italy versus like other parts of the world because because like this kind of haunted house movies seems like a really kind of american an american thing because because like ghost stories in europe are really kind of tied to the culture and since like you know i kind of the way that like this kind</p>
                            <p>Dave (01:14:45.358)
                            i would say spain spain is comparable</p>
                            <p>Dave (01:14:55.358)
                            oh it is this is one hundred percent in american horror story</p>
                            <p>Bryan White (01:15:04.68)
                            sure was colonized in in in the way that it was like it seems like there&#39;s kind of like at least sort of low lying buzz that kind of runs underneath the current that&#39;s like there&#39;s a weird sort of guilt about the way that we feel about the land that we live on and like the people that used to live here and</p>
                            <p>Dave (01:15:21.358)
                            well so that&#39;s why hans holler that&#39;s that was his whole premise and he was not shy about saying that he would go on he would go on any television show radio show whatever he would be like it&#39;s because of the native american burial done and finally i can&#39;t remember the name of the tribe that&#39;s there begin with but i can&#39;t remember in in long island okay so they had to formerly be like first of all that&#39;s not true according to our records there is no any of</p>
                            <p>Bryan White (01:15:35.32)
                            uh lenape</p>
                            <p>Bryan White (01:15:39.34)
                            and in long island yeah the lena</p>
                            <p>Bryan White (01:15:49.5)
                            oh yeah i had</p>
                            <p>Dave (01:15:51.118)
                            is there and then they finally had to be like can you just stop saying that to like it makes us look bad</p>
                            <p>Bryan White (01:15:54.28)
                            yeah i had read that that that even like even like tribal representatives were like well you shut the fuck up like</p>
                            <p>Dave (01:15:58.598)
                            yeah yeah like they had to go like they would also go on t v and be like we don&#39;t know what they&#39;re talking about there&#39;s no records of any of this also that is horse ship can you stop saying it</p>
                            <p>Bryan White (01:16:08.6)
                            yeah yeah but like back to the you know back to the matter in hand like i love the interior of this haunted house like</p>
                            <p>Bryan White (01:16:18.38)
                            polter guys couldn find the crazy haunted ship to a single room this is like caroline&#39;s room throughout the entire house like everything it&#39;s raining the wind is blowing the windows are like opening and like slamming shut it&#39;s it&#39;s just crazy what&#39;s happening in this place</p>
                            <p>Dave (01:16:27.618)
                            and it&#39;s rating</p>
                            <p>Dave (01:16:37.078)
                            yeah this is amity ville plus pultergust plus a little bit of exorcist equals whatever the hell this movie is</p>
                            <p>Bryan White (01:16:44.72)
                            right and then so then we finally danske gets to the to the attic where where sonny&#39;s room is and this is where we finally see possessed evil john mayor</p>
                            <p>Dave (01:16:55.038)
                            and you&#39;re going to get some some real exercise three vibes from this</p>
                            <p>Bryan White (01:16:59.82)
                            yeah yeah right because there&#39;s you know this is the big like exorcism scene proper so he he first of all sunny comes literally flying out of a corner and of the ceiling a really really strange sort of effect and then a damski gets him kind of cornered and they have their kind of uh a little bit a little bit back and forth it&#39;s just a lot of bellowing and the demon tempts</p>
                            <p>Dave (01:17:12.458)
                            of the ceiling</p>
                            <p>Dave (01:17:23.498)
                            kind of a weak exorcism</p>
                            <p>Bryan White (01:17:29.96)
                            but then he does that move like takes his stole and sort of like slowly presents the the cross to the demon and it&#39;s like the most clint eastwood move and the whole thing it&#39;s like the baddest thing the guy has done the whole the whole thing</p>
                            <p>Dave (01:17:43.718)
                            but even when when the demon is trying to tempt him it has this like casualness to it that it makes it work because it&#39;s like this feels like a weird monologue and he just has like a really flat effect it&#39;s a little bit unnerving because of how he&#39;s selling it</p>
                            <p>Bryan White (01:17:54.82)
                            yeah</p>
                            <p>Bryan White (01:18:00.34)
                            right and the way yah because that&#39;s the thing is this guy this guy jack maner i don&#39;t i wish he hadn&#39;t bailed out of act because this this performance is fucking awesome because the way his position is also really strange where his head is kind of off to the side and he&#39;s looking at the priest out of the corner of his eye and then just like casually delivering this thing he&#39;s like you&#39;re all on your own the church is not behind you we can give you what you want you&#39;re like us you know and then they just they just throw caution to the wind yeah</p>
                            <p>Dave (01:18:26.478)
                            also he&#39;s not wrong scared german guy said no and you&#39;re still here and it&#39;s raining inside the house now you hit moses gun on the back of the head you kidnapped this other guy like they&#39;re not letting you back and you&#39;re got taking him up on it</p>
                            <p>Bryan White (01:18:30.98)
                            oh god the</p>
                            <p>Bryan White (01:18:40.86)
                            yeah but also a dam sky for some reason is all banged up like he&#39;s bloody and shet up and all he&#39;s done is walk through the house but then they finally they this is true yeah i suppose that&#39;s true but like this is where they just like they lean in and the whole place goes fucking crazy there the house first of all the house explodes the entire house explodes like from the ground floor to the top floor like they blow the house up</p>
                            <p>Dave (01:18:47.818)
                            yeah</p>
                            <p>Dave (01:18:51.358)
                            well he did jump on him</p>
                            <p>Dave (01:19:10.438)
                            fully furnished house that the let&#39;s family moves into six months later explodes</p>
                            <p>Bryan White (01:19:15.32)
                            yeah and then we get a big guy rubber monster effect and i&#39;m so glad that they did because the monster is so awesome it&#39;s just the crappiest thing in the world</p>
                            <p>Dave (01:19:24.358)
                            yeah it was like it was left over from something and they ere like what about this they were like okay</p>
                            <p>Bryan White (01:19:28.18)
                            yeah yeah yeah same house and like maybe that&#39;s the thing is it&#39;s not really clear like was this before or is this after is it supposed to be the people that died there and then the lotus move in or was this house for sale after the less vacated and these are the people who were just stupid enough not to walk in</p>
                            <p>Dave (01:19:46.998)
                            it exists it exists in sort of limit al space that is amminyville</p>
                            <p>Bryan White (01:19:49.98)
                            i suppose because in the next one which honestly i&#39;m in not really i&#39;m not really in a hurry to see but in amity ville</p>
                            <p>Dave (01:19:56.178)
                            okay but you do need to see the one after that because it&#39;s about a haunted lamp it&#39;s also completely insane vinegar sindrumdid a fork of it it&#39;s great it&#39;s worth buying</p>
                            <p>Bryan White (01:20:01.9)
                            yeah</p>
                            <p>Bryan White (01:20:05.54)
                            that&#39;s awesome but yeah so you&#39;re right so dam ski frees the soul of san who is you know sort of delivered to the police by andrew brian um in the way that he does this</p>
                            <p>Dave (01:20:17.558)
                            endryprian who says who says well what about you and it&#39;s like what about him what do you think he&#39;s going to jail</p>
                            <p>Bryan White (01:20:23.42)
                            yeah but the way that the way that he gets the demon to vacate the boy as he offers himself up and this is that that you know that exerts three moment where take me why you know take me and then yeah one of the low before the credits were always see we see father adam ski</p>
                            <p>Dave (01:20:32.418)
                            yeah</p>
                            <p>Dave (01:20:41.678)
                            which i feel like he hasn&#39;t earned that he hasn&#39;t earned the take me thinks it&#39;s like all right yeah you saw you through the blood all over the house you saw mommy dear screaming her head off but like beyond that and you know that he was sucking his sister like other than that i don&#39;t feel like yu should be offering yourself up you re you have not justified this investment</p>
                            <p>Bryan White (01:20:59.06)
                            maybe like maybe just i&#39;m seriously like i don&#39;t know like this kid like it was fucked before he even moved into the house like maybe just let this one slide because now you&#39;re damned for good so yeah credits roll overall i i got a lot of thoughts on it i loved it i thought it was really really weird it&#39;s it is a fucking crazy and like honestly if it didn&#39;t have that if you didn&#39;t have that</p>
                            <p>Dave (01:21:07.438)
                            yeah</p>
                            <p>Dave (01:21:23.138)
                            it is a wild ride</p>
                            <p>Bryan White (01:21:28.9)
                            if like second act turn where it suddenly becomes just like an entirely other movie i&#39;m not sure i would have liked it as much because like once they do that switch like it goes ape ship in just the best way where clearly somebody was like you got to pick this up like the pace has got to start moving a little quicker</p>
                            <p>Dave (01:21:45.798)
                            so that&#39;s what i mean is that what saves this movie is it&#39;s italians is that they&#39;re like you know what fucking we&#39;re just taking it off the rails let&#39;s go everybody and everyone else is like we already agreed</p>
                            <p>Bryan White (01:21:50.1)
                            yeah</p>
                            <p>Bryan White (01:21:53.82)
                            yeah</p>
                            <p>Bryan White (01:21:58.68)
                            yeah so closing thoughts hat do you think</p>
                            <p>Dave (01:22:01.818)
                            i don&#39;t know that i need to watch it again any time soon having watched it twice</p>
                            <p>Bryan White (01:22:06.38)
                            i probably will not be revisiting it again having seen it twice in the last couple of days i think i&#39;m good for now</p>
                            <p>Dave (01:22:10.958)
                            um and i am someone who watches the same things a lot so i&#39;m not going to come back to this one but i think it is it&#39;s definitely better than the first one it&#39;s better than the third one it&#39;s not better than the haunted lamp</p>
                            <p>Bryan White (01:22:23.96)
                            wait definitely i would say i would say it&#39;s definitely like of the of the ones that i&#39;ve seen just the two and then probably i don&#39;t know i think i think maybe we&#39;ve seen doll house but like if we right rigtbcause like really because the fourth one is really kind of the last of the sequel is formal and then everything that oh that&#39;s right that&#39;s right</p>
                            <p>Dave (01:22:36.778)
                            but jilhus is noncononical</p>
                            <p>Dave (01:22:43.558)
                            there is a fifth one it is about time that&#39;s what it&#39;s called and the clock takes you back and forth it is truly awful</p>
                            <p>Bryan White (01:22:50.84)
                            yeah but like after that it&#39;s just everybody kind of slapping amity ville onto whatever chap they&#39;re making</p>
                            <p>Dave (01:22:54.618)
                            yeah this is this is my favorite non haunted lamp my it&#39;s a low bar thughbecause i don&#39;t love this this series</p>
                            <p>Bryan White (01:22:58.8)
                            it&#39;s a it&#39;s a strap</p>
                            <p>Bryan White (01:23:02.98)
                            all right yeah like this this like i was really not in a hurry to see this but just like catching it in the way that it was presented on in search of darkness i was like i think i got to see this movie um and then once i did a little reading on it i&#39;m like there&#39;s no way i&#39;m not watching this this movie it is a ship show in the best way</p>
                            <p>Dave (01:23:18.318)
                            and this isn&#39;t this is not an a i p move right</p>
                            <p>Bryan White (01:23:21.54)
                            no this actually is which was something that i kind of wanted the first ones in a i p this one is also an a i p u and i think i think amityville was the first a i p movie that was made after cormon vacated to start new</p>
                            <p>Dave (01:23:23.658)
                            it&#39;s the first one is</p>
                            <p>Dave (01:23:36.918)
                            yes because they start getting into our rated films in the mid early to mid seventies</p>
                            <p>Bryan White (01:23:41.08)
                            yeah</p>
                            <p>Bryan White (01:23:45.24)
                            right</p>
                            <p>Bryan White (01:23:47.46)
                            so</p>
                            <p>Dave (01:23:47.518)
                            and it&#39;s like a new generation of people</p>
                            <p>Bryan White (01:23:50.22)
                            correct well so everybody&#39;s got to get up to speed in the eighties the ihties was getting a little was gettin pretty bloody and you got to you got t do bloody business to stay and stay afloat so let&#39;s see what do you want to do next</p>
                            <p>Bryan White (01:24:06.18)
                            we&#39;ve got</p>
                            <p>Bryan White (01:24:08.88)
                            it just this little list friday night too</p>
                            <p>Bryan White (01:24:12.68)
                            and then a couple of those likelystical ones that we had talked about there&#39;s movies that won you over on re watch shockers and then i added one called stephen king rules which would be like stephen king adaptations</p>
                            <p>Dave (01:24:18.778)
                            that&#39;s easily killer clowns</p>
                            <p>Dave (01:24:27.138)
                            i would be hard pressed to find a good stephen king adaptation like a really good one actually salem&#39;s lot is a really good one</p>
                            <p>Bryan White (01:24:30.42)
                            there are some stephen king yeah i know but salem&#39;s lot is one of my favorites of all time</p>
                            <p>Dave (01:24:38.598)
                            toby hooper does a hell of job on that</p>
                            <p>Bryan White (01:24:41.42)
                            um we should do shockers because it&#39;s vague enough</p>
                            <p>Dave (01:24:46.878)
                            i offer up all right i have two recommendations the first is butcher baker nightmare maker</p>
                            <p>Bryan White (01:24:54.64)
                            all right</p>
                            <p>Dave (01:24:57.038)
                            similar vibe to this movie</p>
                            <p>Dave (01:25:00.998)
                            the other is the baby</p>
                            <p>Bryan White (01:25:02.96)
                            oh oh god okay i gonna put both of those on the list</p>
                            <p>Bryan White (01:25:09.78)
                            because i actually</p>
                            <p>Dave (01:25:09.918)
                            i think made for t v is another good category that ere&#39;s a lot of real gold there</p>
                            <p>Bryan White (01:25:13.22)
                            oh yeah sam pancake really kind of covers that one pretty well but i would still oh really</p>
                            <p>Dave (01:25:17.718)
                            not any more he doesn&#39;t he&#39;s switched his focus</p>
                            <p>Bryan White (01:25:24.36)
                            so let&#39;s do but your baker nightmare maker because i am very much looking forward to i have not i would i definitely am looking forward to watching susan tyrell just go crazy</p>
                            <p>Dave (01:25:29.438)
                            you have you seen that movie oh wow susan tyrell is again she is at a ten from the moment the movie opens it is it is jaw dropping film for so it is it is extremely gay uhbostsfenson plays this awful homphobic cop it&#39;s like it is just fucking crazy it&#39;s so good</p>
                            <p>Bryan White (01:25:45.08)
                            i can dig it</p>
                            <p>Bryan White (01:25:48.22)
                            yeah</p>
                            <p>Bryan White (01:25:56.62)
                            all right all right so that&#39;s that&#39;s that&#39;s what we&#39;re going to do we&#39;ll do we&#39;ll do the butcher baker nightmare maker will be the next one that we do i don&#39;t know what what was it night night warning</p>
                            <p>Dave (01:26:06.278)
                            why did they go with that name it had another title that&#39;s like</p>
                            <p>Dave (01:26:13.358)
                            yeah i think that&#39;s what it&#39;s called</p>
                            <p>Bryan White (01:26:15.18)
                            yeah anyway so this is going to conclude the our thoughts on ammadyville to the possession that is crazy ass movie so stick with us we&#39;ll see you next time when we do butcher baker nightmare maker</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/butcher-baker-nightmare-maker">Next Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)